<template>
  <div v-if="isLoading" class="loading">
    <div class="text-center">
      <h5 class="mb-3">Loading...</h5>
      <b-spinner variant="primary" style="width: 4rem; height: 4rem;" />
    </div>
  </div>
  <div v-else-if="!data.length" class="no-data">
    No Data
  </div>
  <div v-else>
     <b-button variant="primary" @click="resetCurrentChart">Reset Topics</b-button>
     <!-- {{ data }} -->
    <div ref="chartContainer" class="chart-container">
      <div style="overflow: hidden;" ref="chart"></div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
name: 'NetworkGraph',
props: {
    data: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: true },
  },

data() {
  return {
    numberOfScatters: null,
    selectedTopics: [],
  };
},
mounted() {
  this.createChart();
},
created() {
    const checkDataLoaded = () => {
      if (this.data && this.data.length > 0) {
        // console.log("Data Loaded");
        this.resetCurrentChart();
      } else {
        // console.log("Data Still Loading");
        setTimeout(checkDataLoaded, 1000); 
      }
    };

    checkDataLoaded();
  },
  methods: {
  createChart() {
    //Adjust the Scatter Plot HTML Size
    const margin = { top: 50, right: 0, bottom: 250, left: 0 };
    const width = 880 - margin.left - margin.right; // Increase the width
    const height = 1000 - margin.top - margin.bottom; // Increase the height
    const radius = Math.min(width, height) / 2 * 0.7; // Adjust the radius to fit within the container

    const svg = d3.select(this.$refs.chart)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left + width / 2},${margin.top + height / 2})`);

    // Create a circular clipping path
    svg.append('clipPath')
      .attr('id', 'circle-clip')
      .append('circle')
      .attr('r', radius);

    // Normalize the pos coordinates to fit within the original circle size
    const maxPosX = d3.max(this.data, d => Math.abs(d.pos[0]));
    const maxPosY = d3.max(this.data, d => Math.abs(d.pos[1]));
    const maxPos = Math.max(maxPosX, maxPosY);

    const x = d3.scaleLinear()
      .domain([-maxPos, maxPos])
      .range([-radius / 1.2, radius / 1.2]); // Use the original radius for scaling

    const y = d3.scaleLinear()
      .domain([-maxPos, maxPos])
      .range([radius / 1.2, -radius / 1.2]); // Use the original radius for scaling

      //Draw Circles Function
      this.drawCircles(svg, radius);

      // Draw Scatter Plot Function
      this.drawScatter(x, y, radius, svg);

      // Draw X and Y Axes *optional* *to check graph accuracy*
      // this.drawAxis(svg, radius, maxPos, x, y);

      // Declare values for the topics and angle step
      this.topics = Array.from(new Set(this.data.flatMap(d => d.topics)));
      const angleStep = 360 / this.topics.length;

      // Draw the topic labels Function
      this.topicLabel(angleStep, svg, radius);

      // Draw the small circles around the main circle Function
      this.labelCircle(angleStep, svg, radius);

    },

    drawLines(svg, x, y, dataPoint, radius) {
      const topics = dataPoint.topics;
      const angleStep = 360 / this.topics.length;

      // Remove existing lines
      svg.selectAll('.topic-line').remove();

      const lineGenerator = d3.line()
        .curve(d3.curveBasis) // Use a curve generator like curveBasis for smooth curves
        .x(d => d[0])
        .y(d => d[1]);

      topics.forEach((topic) => {
        const topicIndex = this.topics.indexOf(topic);
        const topicX = (radius + 16) * Math.cos((angleStep * topicIndex - 90) * (Math.PI / 180));
        const topicY = (radius + 16) * Math.sin((angleStep * topicIndex - 90) * (Math.PI / 180));

        const points = [
          [x(dataPoint.pos[0]), y(dataPoint.pos[1])],
          // Add control points to create a slight bend
          [(x(dataPoint.pos[0]) + topicX) / 1.9, (y(dataPoint.pos[1]) + topicY) / 1.9 - 10],
          [topicX, topicY]
        ];

        svg.append('path')
          .datum(points)
          .attr('class', 'topic-line')
          .attr('d', lineGenerator)
          .style('fill', 'none')
          .style('stroke', '#5C5C5C')
          .style('stroke-width', 1);
      });
    },

    drawCircles(svg, radius) {
      //Create a circle around the scatter plot

      //Main Circle
      svg.append('circle')
      .attr('r', radius * 1.08)
      .style('fill', '#f4f4f4')
      .style('stroke', '#3b5bea')
      .style('filter', 'url(#drop-shadow)');

      //Second Circle
      svg.append('circle')
      .attr('r', radius * 0.9)
      .style('fill', '#f4f4f4')
      .style('stroke', '#bbc4da');
      svg.append('text')
      .attr('x', 0)
      .attr('y', -radius * 0.9)
      .attr('dy', '-0.3em')
      .attr('text-anchor', 'middle')
      .style('fill', '#bbc4da')
      .text('Connection Strength');

      //Third Circle
      svg.append('circle')
      .attr('r', radius * 0.72)
      .style('fill', '#f4f4f4')
      .style('stroke', '#bbc4da');  
      svg.append('text')
      .attr('x', 0)
      .attr('y', -radius * 0.72)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .style('fill', '#bbc4da')
      // .text('70%');
    
      //Fourth Circle
      svg.append('circle')
      .attr('r', radius * 0.54)
      .style('fill', '#f4f4f4')
      .style('stroke', '#bbc4da');   
      svg.append('text')
      .attr('x', 0)
      .attr('y', -radius * 0.54)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .style('fill', '#bbc4da')
      // .text('55%');

      //Fifth Circle
      svg.append('circle')
      .attr('r', radius * 0.36)
      .style('fill', '#f4f4f4')
      .style('stroke', '#bbc4da');  
      svg.append('text')
      .attr('x', 0)
      .attr('y', -radius * 0.36)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .style('fill', '#bbc4da')
      // .text('35%');

      //Sixth Circle
      svg.append('circle')
      .attr('r', radius * 0.18)
      .style('fill', '#f4f4f4')
      .style('stroke', '#bbc4da');  
      svg.append('text')
      .attr('x', 0)
      .attr('y', -radius * 0.18)
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .style('fill', '#bbc4da')
      // .text('0%');
    },

    hideTopics(svg, topics) {
      svg.selectAll('.topic-label')
        .style('fill', d => {
          if (this.selectedTopics.includes(d)) {
            return '#1e90ff'; // Keep the selected topic color as blue
          }
          return topics.includes(d) ? 'black' : 'grey';
        });
    },

    drawScatter(x, y, radius, svg) {
      const scatterPlot = svg.append('g')
      .attr('clip-path', 'url(#circle-clip)');

    const tooltip = d3.select('body').append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background', 'rgba(122, 122, 122, 0.8)')
      .style('padding', '5px')
      .style('max-width', '200px')
      .style('border-radius', '8px')
      .style('overflow', 'hidden')
      .style('box-shadow', '0 4px 8px rgba(0, 0, 0, 0.2)')
      .style('z-index', '1000'); 

    scatterPlot.selectAll('circle-main')
      .data(this.data)
      .enter()
      .append('circle')
      .attr('cx', d => x(d.pos[0]))
      .attr('cy', d => y(d.pos[1]))
      .attr('r', 6)
      .style('cursor', 'pointer')
      .style('fill', '#1e90ff')
      .on('mouseover', (event, d) => {
        d3.select(event.currentTarget).attr('r', 12); // Increase the radius
        this.drawLines(svg, x, y, d, radius);
        this.hideTopics(svg, d.topics);
        this.showCircle(svg, d.topics);
        tooltip.style('visibility', 'visible')
        .html(
          `
      <div>
        <p class="mb-1" style="color: white; font-size: 12px;"><b>${d.text}</b></p>
        <h6 style="color: white; font-size: 10px;"><b>Connection Strength: ${d.strength}</b></h6>
        <h6 style="color: white; font-size: 10px;"><b>Themes: </b></h6>
        <div style="color: white; font-size: 10px; display: flex; flex-wrap: wrap;">
          ${d.topics.map(topic => 
          `<div style="margin-right: 8px;">
            <b>${topic},</b>
           </div>
          `).join('')}
        </div>
        </div>
          `
        );

        // Turn other circles grey
        scatterPlot.selectAll('circle')
          .filter(function() {
            return this !== event.currentTarget;
          })
          .style('fill', '#b8b8b8');
      })
      .on('mousemove', (event) => {
        tooltip.style('top', (event.pageY - 10) + 'px')
          .style('left', (event.pageX + 10) + 'px');
      })
      .on('mouseout', (event) => {
        d3.select(event.currentTarget).attr('r', 6); // Reset the radius
        svg.selectAll('.topic-line').remove();
        svg.selectAll('.topic-label').style('fill', '');
        svg.selectAll('.topic-circle').style('display', 'none');
        tooltip.style('visibility', 'hidden');

      // Reset the color of all circles
      scatterPlot.selectAll('circle')
        .style('fill', '#1e90ff');
        });
    },

    drawAxis(svg, radius, maxPos, x, y) {
      // Draw the x and y axes
      svg.append('line')
        .attr('x1', -radius * 1.04) // Extend the x-axis beyond the circle
        .attr('x2', radius * 1.04)  // Extend the x-axis beyond the circle
        .attr('y1', 0)
        .attr('y2', 0)
        .style('stroke', 'black');

      svg.append('line')
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', -radius)
        .attr('y2', radius)
        .style('stroke', 'black');

      // Add labels to the x and y axes
      const xAxisLabels = [-maxPos, 0, maxPos];
      const yAxisLabels = [-maxPos, 0, maxPos];

      svg.selectAll('.x-axis-label')
        .data(xAxisLabels)
        .enter()
        .append('text')
        .attr('class', 'x-axis-label')
        .attr('x', d => x(d))
        .attr('y', 20) // Adjust the y position of the labels
        .attr('text-anchor', 'middle')
        .text(d => d.toFixed(2));

      svg.selectAll('.y-axis-label')
        .data(yAxisLabels)
        .enter()
        .append('text')
        .attr('class', 'y-axis-label')
        .attr('x', 10) // Adjust the x position of the labels
        .attr('y', d => y(d))
        .attr('text-anchor', 'middle')
        .text(d => d.toFixed(2));
    },

    topicLabel(angleStep, svg , radius) {
      const labelRadius = radius + 30; // Increase the radius for labels to give space

      svg.selectAll('.topic-label')
        .data(this.topics)
        .enter()
        .append('text')
        .style('cursor', 'pointer')
        .style('font-size', '14px')
        .attr('class', 'topic-label')
        .attr('x', (d, i) => {
          const angle = angleStep * i - 90;
          const adjustedRadius = labelRadius; // Fixed radius for end alignment
          return adjustedRadius * Math.cos(angle * (Math.PI / 180));
        })
        .attr('y', (d, i) => {
          const angle = angleStep * i - 90;
          const adjustedRadius = labelRadius; // Fixed radius for end alignment
          return adjustedRadius * Math.sin(angle * (Math.PI / 180));
        })
        .attr('text-anchor', (d, i) => {
          const angle = angleStep * i - 90;
          return angle > 90 && angle < 270 ? 'end' : 'start'; // Flip anchor for left and right sides
        })
        .attr('transform', (d, i) => {
          const angle = angleStep * i - 90;
          const x = labelRadius * Math.cos(angle * (Math.PI / 180));
          const y = labelRadius * Math.sin(angle * (Math.PI / 180));
          const rotation = angle > 90 && angle < 270 ? angle + 180 : angle; // Flip text on the left side
          return `rotate(${rotation}, ${x}, ${y})`;
        })
        .attr('dy', '0.35em')
        .text(d => {
          const maxLength = 25; // Set the maximum length for the text
          return d.length > maxLength ? d.substring(0, maxLength) + '...' : d;
        })

          .on('click', (event, topic) => {
            if (this.selectedTopics.includes(topic)) {
              d3.select(event.target).attr('fill', 'black').style('font-weight', 'normal');
              
              // Hide the circle
              const topicIndex = this.topics.indexOf(topic);
              const angleStep = 360 / this.topics.length;
              const circleLabelRadius = radius + 20;
              const cx = circleLabelRadius * Math.cos((angleStep * topicIndex - 90) * (Math.PI / 180));
              const cy = circleLabelRadius * Math.sin((angleStep * topicIndex - 90) * (Math.PI / 180));
              
              d3.select(event.target.parentNode).selectAll('circle')
                .filter(function() {
                  return d3.select(this).attr('cx') == cx && d3.select(this).attr('cy') == cy;
                })
                .style('display', 'none');
            } else {
              d3.select(event.target).attr('fill', '#1e90ff').style('font-weight', 'bold'); 
              d3.select(event.target).text(topic);

              const topicIndex = this.topics.indexOf(topic);
              const angleStep = 360 / this.topics.length;
              const circleLabelRadius = radius + 20; // Adjust this value to move circles closer to the main circle lines

              // Calculate the position based on the angle and radius
              const cx = circleLabelRadius * Math.cos((angleStep * topicIndex - 90) * (Math.PI / 180));
              const cy = circleLabelRadius * Math.sin((angleStep * topicIndex - 90) * (Math.PI / 180));

              d3.select(event.target.parentNode).append('circle')
                .attr('cx', cx)
                .attr('cy', cy)
                .attr('r', 5)
                .attr('fill', 'none')
                .attr('stroke', '#1e90ff');
            }
            this.filterScatterByTopic(svg, topic);
            // console.log(this.data);
          });
    },

    labelCircle(angleStep, svg, radius) {
      const circleLabelRadius = radius + 20; // Adjust this value to move circles closer to the main circle lines
      const circleRadius = 5; // Radius for the small circles

      svg.selectAll('.topic-circle')
        .data(this.topics)
        .enter()
        .append('circle')
        .attr('class', 'topic-circle')
        .attr('cx', (d, i) => circleLabelRadius * Math.cos((angleStep * i - 90) * (Math.PI / 180)))
        .attr('cy', (d, i) => circleLabelRadius * Math.sin((angleStep * i - 90) * (Math.PI / 180)))
        .attr('r', circleRadius)
        .style('display', 'none')
        .attr('fill', 'none') // Set fill to none
        .attr('stroke', '#1e90ff');
    },

    hideCircle(svg, topics) {
      svg.selectAll('.topic-circle')
        .style('display', d => topics.includes(d) ? 'block' : 'none');
    },

    showCircle(svg, topics) {
      svg.selectAll('.topic-circle')
        .style('display', d => topics.includes(d) ? 'block' : 'none');
    },

    filterScatterByTopic(svg, topic, x, y) {
      // Toggle the selected topic in the array
      const topicIndex = this.selectedTopics.indexOf(topic);
      if (topicIndex === -1) {
        this.selectedTopics.push(topic);
      } else {
        this.selectedTopics.splice(topicIndex, 1);
      }

      // If no topics are selected, show all circles
      if (this.selectedTopics.length === 0) {
        // svg.selectAll('circle').style('display', 'block');
        this.resetCurrentChart();
        return;
      }

      // Filter the data based on the currently selected topics
      const filteredData = this.data.filter(d =>
        this.selectedTopics.every(selectedTopic => d.topics.includes(selectedTopic))
      );

      // Update the scatter plot with the filtered data
      const scatterPlot = svg.select('g');

      // Select all circles, and bind them to filtered data
      const circles = scatterPlot.selectAll('circle')
        .data(this.data, d => d.text); // Bind to original data

      // Update display based on filtered data: hide circles not in filtered data, show those in it
      circles.style('display', d => filteredData.includes(d) ? 'block' : 'none');

      // Update or create circles for filtered data
      circles.enter().append('circle')
        .attr('cx', d => x(d.pos[0]))
        .attr('cy', d => y(d.pos[1]))
        .attr('r', 12)
        .style('fill', 'blue')
        .style('display', d => filteredData.includes(d) ? 'block' : 'none')
        .merge(circles);
        // .attr('cx', d => x(d.pos[0]))
        // .attr('cy', d => y(d.pos[1]));

    },

    resetCurrentChart(){
      const svg = d3.select(this.$refs.chart).select('svg');
      svg.remove();
      this.createChart();
      this.selectedTopics = [];
    },
    // addCurrentChart() {
    //   this.createChart();
    //   console.log('Current chart added.');
    // },
    // removeCurrentCharts() {
    //   const svg = d3.select(this.$refs.chart).select('svg');
    //   svg.remove();
    //   console.log('Current charts removed.');
    // }
}
};
</script>

<style scoped>
.chart-container {
display: flex;
margin-top: 10px;
width: 880px;
height: 1000px;
/* justify-content: center; */
align-items: center;
overflow: hidden;
}

  </style>