<template>
  <div v-if="isLoading || loading === true"  class="loading">
    <div class="text-center">
      <h5 class="mb-3">Loading...</h5>
      <b-spinner variant="primary" style="width: 4rem; height: 4rem;" />
    </div>
  </div>

  <div v-else class="row">
    <!-- {{ list }} -->
    <!-- <p><b>keyword_trend_prediction"{{ selectedKeywords }}"</b></p>
    <div>
      <b-button @click="resetSelectedKeywords" variant="outline-primary">RESET</b-button>
    </div>
    <div>
      <b-button @click="customTooltip" variant="outline-primary">DEBUG TOOLTIP</b-button>
    </div>  -->

    <b-container fluid class="mt-2 mb-4">
      <b-row class="flex-nowrap scrollable-row" style="overflow-x: auto; white-space: nowrap;">
        <b-col v-for="(item, index) in list" :key="index" style="border-style: solid; border-radius: 10px; border-color: lightgray; border-width: 1.5px;" cols="4" class="m-2">
          
          <div v-if="item.related_keyword_data && item.query_keyword_data === null" class="m-2 pt-3">
            <div class="d-flex align-items-center flex-column" v-if="index === 0">
              <div v-if="!hasSelectedKeywords">
                <p class="text-center">Keyword : <br><b>{{ item.keyword }}</b></p>
              </div>
              <div v-else>
                <p class="text-center">Keyword {{ index + 1 }} : <br><b>{{ item.keyword }}</b></p>
              </div>
            </div>
            <div class="p-2 d-flex align-items-center flex-column" v-if="!hasSelectedKeywords">
              <div class="mb-2" style="word-wrap: break-word; white-space: pre-wrap; word-break: break-word; text-align: center;">
                <p class="text-center"><b>{{ item.keyword }}</b> has insufficient data</p> <br>
              </div>
              <p class="mb-2">Choose related keywords to compare :</p>
              <div v-for="(keyword, keywordIndex) in item.related_keyword_data" :key="keywordIndex">
                <button
                  :value="keyword.keyword"
                  @click="toggleKeyword(keyword.keyword)"
                >
                  <u>{{ keyword.keyword }}</u>
                </button>
              </div>
            </div>
            <div class="d-flex align-items-center flex-column" v-else >
              <p>Data is sufficient</p>
            </div>
          </div>

          <div v-else-if="item.query_keyword_data" class="m-2 pt-3 d-flex align-items-center flex-column">
            <p class="text-center">Keyword {{ index + 1 }} : <br><b>{{ item.keyword }}</b></p>
            <p>Data is sufficient</p>
          </div>

          <div v-else class="d-flex align-items-center flex-column" >
            <p>Data Not Found</p>
          </div>
        </b-col>

        <b-col style="border-style: solid; border-radius: 10px; border-color: lightgray; border-width: 1.5px;" cols="4" class="m-2 d-flex align-items-center justify-content-center">
          <div class="m-4 d-flex align-items-center flex-column">
            <!-- <p>Keyword : <b>NULL</b></p>  -->
            <b-button v-b-modal.modal-1 class="m-2" variant="outline-danger">Add New Keyword</b-button>
          </div>

          <b-modal id="modal-1" hide-header hide-footer @hidden="resetErrorMessage">
            <div class="p-2">
              <h5>Add New Keyword</h5>
              <b-form @submit.prevent="emitKeyword">
                <b-form-group label="Keyword:" label-for="keyword-input">
                  <b-form-input
                    id="keyword-input"
                    v-model="newKeyword"
                    required
                    placeholder="Enter keyword"
                    @focus="resetErrorMessage"
                  ></b-form-input>
                </b-form-group>
                <div class="mt-2 ms-1">
                  <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
                </div>
                <div class="pt-3 d-flex align-items-end flex-column">
                  <b-button type="submit" variant="primary">Add Keyword</b-button>
                </div>
              </b-form>
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>

    
    <div>
      <!-- Display related_keyword_data with checkboxes -->
      <div v-for="(item, index) in list" :key="index">

        <!-- <b>{{ item.keyword }}</b> -->
        
          <div v-if="item.query_keyword_data !== null">
            <div v-for="(keyword, index) in item.query_keyword_data" :key="index">
              <!-- <label>{{ keyword }}</label> -->
            </div>
          </div>
      </div>

      <!-- Legends -->
      <div class="">
          <tr class="d-flex align-items-center justify-content-center flex-column bd-highlight mb-3">
              <div class="text-center mb-2">
                <button 
                  v-for="(seriesItem, seriesIndex) in legendData(list)" 
                  :key="seriesIndex" 
                  @click="toggleSeries(seriesItem.name, seriesIndex)" 
                  :style="{cursor: 'pointer', marginRight: '10px'}">
                  <span :style="{ backgroundColor: seriesItem.color }" class="dot me-2"></span>
                  <span :id="'series-title-' + seriesIndex" style="font-size: 15spanx;"><i>{{ seriesItem.display_name }}</i></span>
                </button>
              </div>
          </tr>
        </div>

      <!-- Chart -->
      <div id="chart">
        <apexchart
          ref="chart"
          type="rangeArea"
          height="400"
          :options="chartOptions"
          :series="combinedSeries(list)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import nexIntelligence from '@/store/NexIntelligence';

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    list: { type: Array, default: () => [] },
    isLoading: { type: Boolean, default: true },
    selectedKeywords:{ type: Array, default: () => []},
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      errorMessage: '',
      newKeyword: '',
      showKeywords: false,
      showAddKeywordButton: true,
      showAddNewKeywordButton: true,
      chartOptions: {
        chart: {
          height: 350,
          type: 'rangeArea',
          animations: {
            speed: 500
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
        },
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: '#000000', 
            fontSize: '14px',
            fontFamily: 'Arial, sans-serif' 
          }
        },
        series:[],
        // fill: this.generateOpacity(),
        stroke: this.generateStroke(),
        title: {
          text: 'Compare & Predict'
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
        title: {
          text: 'Search Volume',
        },
        max: 150,
        min: -50,
      },
        tooltip: {
          enabled: true,
          enabledOnSeries: [0],
          shared: true,
          followCursor: true,
          custom: this.customTooltip,
          fillSeriesColor: false,
          theme: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined
          },
          onDatasetHover: {
              highlightDataSeries: false,
          },
      },
    
      }
    }
  },
  methods: {
    async fetchKeywords() {
      try {
        const { data } = await nexIntelligence.fetchPrediction(this.$route.params.id);

        if (data) {
          this.keywords_trend = data.data.keyword_trend_prediction;
          // console.log("Keywords Trend Data",this.keywords_trend);
          data.data.values.forEach(({ key, value }) => {
            if (key === 'keyword_trend_prediction') this.list = value;
          });
          // console.log("Fetched Data For EACH",this.list);
        }
      } catch (error) {
        console.log(error);
      } 
    },
    customTooltip({ series, seriesIndex, dataPointIndex, w }) {
      const seriesName = w.globals.seriesNames[seriesIndex];
      const data = series[seriesIndex][dataPointIndex];
      const color = w.globals.colors[seriesIndex];
      // console.log("Color:", color);
      // console.log("Series Name:", seriesName);
      // console.log("Data:", data);

      // Check if the current series is "Predicted"
      if (seriesName.includes('Predicted') || seriesName.includes('Actual')) {
        const name = seriesName.split(/ - (Actual|Predicted|CI_70|CI_90)/)[0];
        // console.log("Name:", name);

        // Return the custom tooltip for "Predicted" series
        return `<div class="custom-tooltip p-2" style="background-color: white;">
                  <span><strong style="color: ${color};">${name}</strong></span><br>
                  <span><strong>Value:</strong> ${data}</span><br>
                </div>`;
      }

      // Return the default tooltip for other series
      return "";
    },
    // resetSelectedKeywords() {
    //   this.selectedKeywords = [];
    // },
    resetErrorMessage() {
      this.errorMessage = '';
    },
    toggleKeyword(keyword) {
      const id = this.$route.params.id;

      const formData = new FormData();
      formData.append('keyword_trend_prediction', keyword);
      // console.log("FormData:", formData);

      this.loading = true;

      nexIntelligence.updateKeywords(id, formData)
      .then(() => {
          this.selectedKeywords.push(keyword);
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error updating keyword_trend_prediction:", error);
        });
    },

    emitKeyword() {
        if (!this.newKeyword) {
          this.errorMessage = 'Keyword is required!';
          return;
        }
        const payload = {
          keywords: this.newKeyword
        };
        this.$emit('keyword-added', payload);
    },

    // Toggle series visibility
    toggleSeries(seriesName, seriesIndex) {
      const chartRef = this.$refs.chart;
      if (chartRef && typeof chartRef.toggleSeries === 'function') {
        
        const [keyword, sourcePart] = seriesName.split(" - ");
        const source = sourcePart.match(/\(([^)]+)\)/)[1];

        const seriesTypes = ['Actual', 'Predicted', 'CI_70', 'CI_90'];
        
        seriesTypes.forEach((type) => {
          const fullSeriesName = `${keyword} - ${type} (${source})`;
          chartRef.toggleSeries(fullSeriesName); 
          // console.log("Toggled series:", fullSeriesName);
        });

        const seriesTitleElement = document.getElementById('series-title-' + seriesIndex);
        if (seriesTitleElement.style.color === 'grey') {
          seriesTitleElement.style.color = 'black';
        } else {
          seriesTitleElement.style.color = 'grey';
        }

      } else {
        console.error("toggleSeries is not a function on the chart instance");
      }
    },
      // Generate legend data
    legendData(item) {
      const series = this.combinedSeries(item);
      const legend = series
        .filter(s => s.type === 'line' && s.name.includes(' - Actual ('))
        .map(s => ({
          name: s.name,
          display_name: s.display_name,
          color: s.color
        }));
      return legend;
   },
    // Check all legends
    //   checkLegends() {
    //   const allLegends = document.querySelectorAll(".custom-legend span");
    //   allLegends.forEach(legend => {
    //     const seriesName = legend.innerText;
    //     if (!legend.classList.contains('active')) {
    //       this.toggleSeries(seriesName);
    //     }
    //   });
    // },

    generateStroke() {
      const strokeWidth = [];

      this.list.forEach((item) => {
        const relatedData = Array.isArray(item.related_keyword_data) ? item.related_keyword_data : [];
        const queryData = Array.isArray(item.query_keyword_data) ? item.query_keyword_data : (item.query_keyword_data ? [item.query_keyword_data] : []);

        const combinedData = [...relatedData, ...queryData];
        combinedData.forEach(() => {
          
          const lineTypes = ['Actual', 'CI_70', 'CI_90', 'Predicted'];
          const strokeWidths = {
            'Actual': 2,  
            'CI_70': 0,  
            'CI_90': 0,
            'Predicted': 2,
          };

          lineTypes.forEach((type) => {
            strokeWidth.push(strokeWidths[type]);
          });
        });
      });

      return {
        curve: 'smooth',
        width: strokeWidth,
      };
    },

    generateColorForKeyword(keyword, usedColors) {
      const colorPalette = [
      'rgba(255, 0, 0, 0.5)',    // Red
      'rgba(0, 0, 255, 0.5)',    // Blue
      'rgba(0, 255, 0, 0.5)',    // Green
      'rgba(255, 255, 0, 0.5)',  // Yellow
      'rgba(255, 165, 0, 0.5)',  // Orange
      'rgba(75, 0, 130, 0.5)',   // Indigo
      'rgba(148, 0, 211, 0.5)',  // Violet
      'rgba(255, 20, 147, 0.5)', // DeepPink
      'rgba(128, 0, 128, 0.5)',  // Purple
      'rgba(255, 69, 0, 0.5)',   // OrangeRed
      'rgba(0, 128, 128, 0.5)',  // Teal
      'rgba(0, 255, 255, 0.5)',  // Cyan
      'rgba(255, 105, 180, 0.5)',// HotPink
      'rgba(139, 69, 19, 0.5)',  // SaddleBrown
      'rgba(0, 100, 0, 0.5)',    // DarkGreen
      'rgba(255, 140, 0, 0.5)',  // DarkOrange
      'rgba(75, 0, 130, 0.5)',   // Indigo
      'rgba(255, 0, 255, 0.5)',  // Magenta
      'rgba(0, 0, 139, 0.5)',    // DarkBlue
      'rgba(255, 228, 181, 0.5)' // Moccasin
    ];

      // If we still have unused colors in the palette
      if (usedColors.length < colorPalette.length) {
        return colorPalette[usedColors.length]; // Return the next color in the palette
      } 

      // Generate a bright random color once the palette is exhausted
      const randomBrightColor = () => Math.floor(Math.random() * 156) + 100; // Ensures brightness (values between 100 and 255)
      const alpha = 0.5; // Adjust alpha transparency as needed
      return `rgba(${randomBrightColor()}, ${randomBrightColor()}, ${randomBrightColor()}, ${alpha})`;
    },

    combinedSeries(items) {
      const keywords = this.selectedKeywords;
      const usedColors = []; // Keep track of used colors
      
      const series = items.flatMap((item) => {
        const relatedData = Array.isArray(item.related_keyword_data) ? item.related_keyword_data : [];
        const filteredRelatedData = relatedData.filter(relatedItem => keywords.includes(relatedItem.keyword));

        let queryData = [];
        if (Array.isArray(item.query_keyword_data)) {
          queryData = item.query_keyword_data;
        } else if (item.query_keyword_data && typeof item.query_keyword_data === 'object') {
          queryData = [item.query_keyword_data];
        }

        if ((!filteredRelatedData || filteredRelatedData.length === 0) && (!queryData || queryData.length === 0)) {
          return [];
        }

        // Combine the data from both sources
        const combinedData = [
          ...filteredRelatedData.map((d) => ({ ...d, source: 'related' })),
          ...queryData.map((d) => ({ ...d, source: 'query' })),
        ];

        return combinedData.flatMap((keywordData) => {
          const color = this.generateColorForKeyword(keywordData.keyword, usedColors); // Use a single color for the keyword
          usedColors.push(color); // Mark the color as used

          // Generate data for stats, predictions, confidence intervals
          const seriesData = (keywordData.stats || []).map((stat) => ({
            x: new Date(stat.index),
            y: Math.round(stat.search_volume * 100),
          }));

          const predictData = (keywordData.predict || []).map((predictItem) => ({
            x: new Date(predictItem.index),
            y: Math.round(predictItem.value * 100),
          }));

          const upper_ci_70 = (keywordData.upper_ci_70 || []).map((upper70) => ({
            x: new Date(upper70.index),
            y: Math.round(upper70.value * 100),
          }));

          const lower_ci_70 = (keywordData.lower_ci_70 || []).map((lower70) => ({
            x: new Date(lower70.index),
            y: Math.round(lower70.value * 100),
          }));

          const upper_ci_90 = (keywordData.upper_ci_90 || []).map((upper90) => ({
            x: new Date(upper90.index),
            y: Math.round(upper90.value * 100),
          }));

          const lower_ci_90 = (keywordData.lower_ci_90 || []).map((lower90) => ({
            x: new Date(lower90.index),
            y: Math.round(lower90.value * 100),
          }));

          const ci_70 = predictData.map((predictItem, index) => ({
            x: new Date(predictItem.x),
            y: [parseFloat(lower_ci_70[index]?.y || 0), parseFloat(upper_ci_70[index]?.y || 0)],
          }));

          const ci_90 = predictData.map((predictItem, index) => ({
            x: new Date(predictItem.x),
            y: [parseFloat(lower_ci_90[index]?.y || 0), parseFloat(upper_ci_90[index]?.y || 0)],
          }));

          return [
            {
              name: `${keywordData.keyword} - Actual (${keywordData.source})`,
              display_name: keywordData.keyword,
              data: seriesData,
              type: 'line',
              color: color,
            },

            {
              name: `${keywordData.keyword} - CI_70 (${keywordData.source})`,
              data: ci_70,
              type: 'rangeArea',
              color: color,
            },
            {
              name: `${keywordData.keyword} - CI_90 (${keywordData.source})`,
              data: ci_90,
              type: 'rangeArea',
              color: color,
            },
            {
              name: `${keywordData.keyword} - Predicted (${keywordData.source})`,
              data: predictData,
              type: 'line',
              color: color,
            },
          ];
        });
      });

      return series;
    },

  },
  computed: {
    hasSelectedKeywords() {
      return this.selectedKeywords.length > 0;
    },
    // columns() {
    //   // Ensure at least one column
    //   return [...this.selectedKeywords, null];
    // },

  },
  // mounted() {
  //   this.$nextTick(() => {
  //     this.checkLegends();
  //   });
  // },
}
</script>

<style scoped>
.dot {
  margin-top: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}
</style>