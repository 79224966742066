<template>
    <div v-if="isLoading">
        <div class="text-center">
        <h5 class="mb-3">Loading...</h5>
        <b-spinner variant="primary" style="width: 4rem; height: 4rem;" />
        </div>
    </div>
    <div v-else-if="!list.length">
        No Data
    </div>
    <div v-else>
      <h5><b>Topics:</b></h5>
      <!-- Components Topics List -->
      <Overall :data="list[0].overall" :selectedItem="selectedItem" @item-selected="handleItemSelected" />
      <Facebook :data="list[1].facebook" :selectedItem="selectedItem" @item-selected="handleItemSelected" />
      <Instagram :data="list[2].instagram" :selectedItem="selectedItem" @item-selected="handleItemSelected"/>
      <Linkedin :data="list[3].linkedin" :selectedItem="selectedItem" @item-selected="handleItemSelected"/>
      <Twitter :data="list[4].twitter" :selectedItem="selectedItem" @item-selected="handleItemSelected"/>
      <YouTube :data="list[5].youtube" :selectedItem="selectedItem" @item-selected="handleItemSelected"/>
      <Reddit :data="list[6].reddit" :selectedItem="selectedItem" @item-selected="handleItemSelected"/>
      <Threads :data="list[7].threads" :selectedItem="selectedItem" @item-selected="handleItemSelected"/>

      <!-- TABS  -->
      <div v-if="selectedItem" ref="detailsSection" class="mt-4">
        <h5><b>Details:</b></h5>
        <div class="mt-4 item-container">
          <p><b>Topic: </b><span>{{ selectedItem.topic }}</span></p>
          <p><b>Topic Keywords: </b><span>{{ selectedItem.topic_keywords.join(', ') }}</span></p>
          <p><b>Tones: </b><span>{{ selectedItem.tones.join(', ') }}</span></p>
          <p><b>Writing Styles: </b><span>{{ selectedItem.writing_styles.join(', ') }}</span></p>
          <p><b>Intents: </b><span>{{ selectedItem.intents.join(', ') }}</span></p>
          <p><b>Public Post URLs: </b>
            <ol>
              <li v-for="url in visibleUrls" :key="url" class="m-1">
                <a :href="url" target="_blank">{{ truncateURL(url) }}</a>
              </li>
            </ol>
          </p>
          <div class="d-flex justify-content-center">
            <div>
              <button v-if="selectedItem.public_post_url.length > 5" @click="showMore = !showMore">
                {{ showMore ? 'Show Less' : 'Show More' }}
              </button>
              <b-icon v-show="selectedItem.public_post_url.length > 5 && !showMore" icon="chevron-double-down"></b-icon>
              <b-icon v-show="selectedItem.public_post_url.length > 5 && showMore" icon="chevron-double-up"></b-icon>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="generatePost(selectedItem)">Generate Post</b-button>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
import Overall from './Overall.vue';
import Facebook from './Facebook.vue';
import Instagram from './Instagram.vue';
import Linkedin from './Linkedin.vue';
import Twitter from './Twitter.vue';
import YouTube from './Youtube.vue';
import Reddit from './Reddit.vue';
import Threads from './Threads.vue';

  export default {
    name: 'SocialSentiment',
    components: {
        Overall,
        Facebook,
        Instagram,
        Linkedin,
        Twitter,
        YouTube,
        Reddit,
        Threads,
    },
    props: {
      list: { type: Array, default: () => [] },
      isLoading: { type: Boolean, default: true },
      selectedTopic: { type: Object, default: null },
    },
    data() {
      return {
        selectedItem: null,
        showMore: false,
      }
    },
    computed: {
    visibleUrls() {
      return this.showMore ? this.selectedItem.public_post_url : this.selectedItem.public_post_url.slice(0, 5);
    }
  },
    methods: {
      handleItemSelected(item) {
      this.selectedItem = item;
      this.showMore = false;
      this.$nextTick(() => {
        this.$refs.detailsSection.scrollIntoView({ behavior: 'smooth' });
      });
    },
      truncateURL(url, length = 80) {
        return url.length > length ? url.substring(0, length) + '...' : url;
      },
      generatePost(item) {
        // console.log('Generating post for:', item);
        this.$emit('topic-selected', item);
      },
    },
  }
  </script>

  <style scoped>
.item-container {
  padding: 10px;
  border-style: solid;
  border-radius: 10px;
  border-color: lightgray;
  border-width: 1.5px;
  overflow-x: hidden; 
  word-wrap: break-word;
  word-break: break-word;
}
  </style>
