<template>
  <div class="m-3">
    <h4 v-if="is_selecting_topic && !selectedTopic" class="text-center modal-card__title mt-5">
      Please Select a Topic for {{ platform_options[selectedPlatform] }}
    </h4>
    <h4 v-else class="text-center modal-card__title mt-5">
      Generate Post for {{ platform_options[selectedPlatform] }}
    </h4>

    <form
      v-if="is_selecting_topic && !selectedTopic"
      class="card__inputbody pt-3"
      style="width: 90%"
      @submit.prevent
    >
      <div class="row">
        <div class="col card_inputgroup__title" style="color: #355239">
          {{ "Topics covered in audience research" }}
        </div>
      </div>
      <div class="row mt-2 mb-3 w-100 align-self-center">
        <div class="col card_inputgroup">
          <div class="row">
            <div
              v-for="(item, index) in topicsIdea?.[model]?.topics_covered"
              :key="index"
              class="form-check mt-1"
            >
              <input
                v-model="topic"
                :value="item"
                :name="item"
                :id="'topic' + index"
                class="form-check-input"
                type="radio"
              />
              <label class="form-check-label" :for="'topic' + index">
                {{ item }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col card_inputgroup__title" style="color: #355239">
          {{ "New topic ideas" }}
        </div>
      </div>
      <div style="width: 100%; align-self: center" class="row mt-2">
        <div class="col card_inputgroup">
          <div class="row">
            <div
              v-for="(item, index) in topicsIdea?.[model]?.topics_not_covered"
              :key="index"
              class="form-check mt-1"
            >
              <input
                v-model="topic"
                :value="item"
                :name="item"
                :id="'new-topic' + index"
                class="form-check-input"
                type="radio"
              />
              <label class="form-check-label" :for="'new-topic' + index">
                {{ item }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form class="card__inputbody pt-3" v-else @submit.prevent>
      <div class="text-danger text-sm">* required</div>
      <!-- Topic -->
      <div class="card_inputgroup">
        <span class="card_inputgroup__title">
          {{ "Topic" | multilingual }}
          <span class="text-danger text-sm">*</span>
        </span>
        <div v-if="!selectedTopic" class="w-100 d-flex flex-column">
          <b-form-textarea
            v-model="topic"
            name="Topic"
            type="text"
            id="topic-textarea"
            class="form-control rounded w-100"
            autocorrect="off"
            autocomplete="off"
            aria-label="Topic"
            aria-describedby="Topic"
            v-validate="'required'"
            rows="2"
            max-rows="2"
          />

          <span class="text-danger text-sm" v-show="errors.has('Topic')">
            {{ errors.first("Topic") }}
          </span>
        </div>

        <div v-else class="w-100 d-flex flex-column">
          <b-form-textarea
            v-model="selectedTopic.topic"
            name="Topic"
            type="text"
            id="topic-textarea"
            class="form-control rounded w-100"
            autocorrect="off"
            autocomplete="off"
            aria-label="Topic"
            aria-describedby="Topic"
            v-validate="'required'"
            rows="2"
            max-rows="2"
          />

          <span class="text-danger text-sm" v-show="errors.has('Topic')">
            {{ errors.first("Topic") }}
          </span>
        </div>
      </div>

      <!-- {{ selectedTopic }} -->

      <!-- Target Phrase -->
      <div class="card_inputgroup">
        <span class="card_inputgroup__title">
          {{ "Target Phrase" | multilingual }}
        </span>

        <div class="w-100 d-flex flex-column">
          <InputChip
            v-model="target_phrase"
            placeholder="Target Phrase"
            :dropdown-placeholder="$options.filters.multilingual('Enter your phrase')"
          />
        </div>
      </div>

      <!-- Target Hashtags -->
      <div class="card_inputgroup">
        <span class="card_inputgroup__title">
          {{ "Target Hashtags" | multilingual }}
        </span>

        <div class="w-100 d-flex flex-column">
          <InputChip
            v-model="target_hashtags"
            placeholder="Target Hashtags"
            :dropdown-placeholder="$options.filters.multilingual('Enter your hashtag')"
            prepend-content="#"
          />
        </div>
      </div>

      <!-- Language -->
      <div class="card_inputgroup">
        <span class="card_inputgroup__title">
          {{ "Language" | multilingual }}
          <span class="text-danger text-sm">*</span>
        </span>
        <div>
          <div class="input-group">
            <div
              v-for="language in language_options"
              :key="language.value"
              class="form-check form-check-inline"
            >
              <input
                v-model="language_list"
                :value="language.value"
                :id="language.value"
                class="form-check-input"
                name="Language"
                type="checkbox"
                v-validate="'required'"
              />
              <label class="form-check-label" :for="language.value">
                {{ language.text }}
              </label>
            </div>
          </div>
          <span
            v-show="errors.has('Language')"
            class="text-danger text-sm"
          >
            {{ errors.first("Language") }}
          </span>
        </div>
      </div>

      <!-- Tone of Voice -->
      <div class="card_inputgroup">
        <span class="card_inputgroup__title">
          {{ "Tone of Voice" | multilingual }}
        </span>
        <div class="w-100 d-flex flex-column">
          <InputChip
            v-model="tone_value"
            placeholder="Choose up to 3 tones"
            :dropdown-placeholder="$options.filters.multilingual('Enter your own tone')"
            :max-length="3"
            :options="tone_options"
            @error="handlerErrorInputChip"
            dropdown-placement="top"
          />
          <span class="text-danger text-sm" v-show="errors.has('tone')">
            {{ errors.first("tone") }}
          </span>
          <span class="text-danger text-sm" v-if="exceeded_tone">
            {{ "Choose 3 tones only" }}
          </span>
        </div>
      </div>


      <!-- Writing Styles -->
      <div v-if="selectedTopic" class="card_inputgroup">
        <span class="card_inputgroup__title">
          {{ "Writing Styles" | multilingual }}
        </span>
        <div class="w-100 d-flex flex-column">
          <InputChip
            v-model="writing_styles"
            placeholder="Writing Styles"
            :dropdown-placeholder="$options.filters.multilingual('Enter your writing style')"
          />
          <span class="text-danger text-sm" v-show="errors.has('tone')">
            {{ errors.first("tone") }}
          </span>
          <span class="text-danger text-sm" v-if="exceeded_tone">
            {{ "Choose 3 tones only" }}
          </span>
        </div>
      </div>


      <!-- Intents -->
      <div v-if="selectedTopic" class="card_inputgroup">
        <span class="card_inputgroup__title">
          {{ "Intents" | multilingual }}
        </span>
        <div class="w-100 d-flex flex-column">
          <InputChip
            v-model="intents"
            placeholder="Intents"
            :dropdown-placeholder="$options.filters.multilingual('Enter your writing style')"
          />
          <span class="text-danger text-sm" v-show="errors.has('tone')">
            {{ errors.first("tone") }}
          </span>
          <span class="text-danger text-sm" v-if="exceeded_tone">
            {{ "Choose 3 tones only" }}
          </span>
        </div>
      </div>
    </form>

    <div
      :class="[
        'd-flex',
        topic_error && !topic ? 'justify-content-between' : 'justify-content-end',
        'align-items-center'
      ]"
    >
      <p v-if="topic_error && !topic" class="text-sm text-danger mb-0">
        Please choose 1 topic from the above list
      </p>
      <div class="d-flex gap-3">
        <button class="btn btn-grey-outlined" @click="previous">
          {{ "Previous" | multilingual }}
        </button>
        <button v-if="is_selecting_topic && !selectedTopic" class="btn btn-primary" @click="next">
          {{ "Next" | multilingual }}
        </button>
        <button v-else class="btn btn-primary" @click="generateNewContent">
          {{ (isLastForm ? "Generate" : "Next") | multilingual }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputChip from '@/components/InputChip';
export default {
  components: { InputChip },
  props: {
    topicsIdea: { type: Object, default: () => ({}) },
    selectedPlatform: { type: String, default: '' },
    formValue: { type: Object, default: () => ({}) },
    isLastForm: { type: Boolean, default: false },
    selectedTopic: { type: Object, default: null },
  },
  data() {
    return {
      is_selecting_topic: true,
      platform_options: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        twitter: 'Twitter'
      },
      language_options: [
        { value: 'en', text: 'English' },
        { value: 'ms', text: 'Malay' },
        { value: 'zh-cn', text: 'Mandarin' },
      ],
      tone_options: [
        'casual',
        'descriptive',
        'enthusiastic',
        'humorous',
        'informative',
        'inspirational',
        'joyful',
        'marketing',
        'passionate',
        'persuasive',
        'professional',
        'relaxed',
        'thoughtful',
      ],
      model: 'default',
      topic: this.formValue?.topic || '',
      target_phrase: !this.formValue?.target_phrase ? [] : this.formValue.target_phrase.split(', '),
      target_hashtags: !this.formValue?.target_hashtags ? [] : this.formValue.target_hashtags.split(', '),
      language_list: !this.formValue?.language?.length ? [] : this.formValue.language.slice(),
      tone_value: !this.formValue?.tones ? [] : this.formValue.tones.split(', '),
      writing_styles: this.selectedTopic?.writing_styles || [],
      intents: this.selectedTopic?.intents || [],
      topic_error: false,
      exceeded_tone: false,
    };
  },
  methods: {
    next() {
      if (!this.topic && !this.selectedTopic) this.topic_error = true;
      else {
        const indexNotCovered = this.topicsIdea.default.topics_not_covered_params
          .findIndex((item) => item.topic === this.topic);

        if (indexNotCovered !== -1) {
          const topicParams = this.topicsIdea.default.topics_not_covered_params[indexNotCovered];
          this.target_phrase = topicParams.target_phrase.split(', ');
          this.target_hashtags = topicParams.target_hashtags.split(', ');
        } else {
          const indexCovered = this.topicsIdea.default.topics_covered_params
            .findIndex((item) => item.topic === this.topic);

          if (indexCovered !== -1) {
            const topicParams = this.topicsIdea.default.topics_covered_params[indexCovered];
            this.target_phrase = topicParams.target_phrase.split(', ');
            this.target_hashtags = topicParams.target_hashtags.split(', ');
          } 
        }
        this.is_selecting_topic = false;
      }
    },
    previous() {
      if (!this.is_selecting_topic) this.is_selecting_topic = true;
      else this.$emit('click-previous');
    },
    handlerErrorInputChip(error) {
      this.exceeded_tone = error.max_length_exceeded ?? false;  
    },

     // Original generateNewContent() method
    async generateNewContent() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) return;

      const payload = {
        language: this.language_list,
        topic: this.topic || this.selectedTopic.topic,
      };
      if (this.tone_value.length) payload.tones = this.tone_value.join(', ');
      if (this.target_phrase.length) payload.target_phrase = this.target_phrase.join(', ');
      if (this.target_hashtags.length) payload.target_hashtags = this.target_hashtags.join(', ');
      if (this.writing_styles.length) payload.writing_styles = this.writing_styles.join(', ');
      if (this.intents.length) payload.intents = this.intents.join(', ');

      console.log('payload', payload);
      console.log("Writing Styles", this.writing_styles);
      console.log("Intents", this.intents);

      this.$emit('submit', payload, this.selectedPlatform, this.isLastForm);
    }
  }
}
</script>

<style lang="css" scoped>
.form-check-input {
  border: 1px solid #c2c2c2;
}
.form-check-input:checked {
  border: 1px solid #0d6efd;
}
</style>
