<template>
  <div>
    <b-container fluid class="m-2">
      <b-row>
        <b-col sm="1" class="d-flex justify-content-center align-items-center">
        </b-col>
        <b-col sm="1" class="d-flex justify-content-center align-items-center  pe-5">
          <h6><b>Overall</b></h6>
        </b-col>
        <b-col sm="1" class="d-flex justify-content-center pe-0">
          <button v-if="data.length > 2" @click="scrollLeft">
            <b-icon icon="chevron-left"></b-icon>
          </button>
        </b-col>
        <b-col ref="scrollContainer" class="p-0" sm="8" style="overflow-x: hidden; white-space: nowrap;">
          <div v-for="(item, index) in data" :key="index" style="" cols="4" class="m-2 custom-scrollbar">
              <p 
              :style="{ margin: '5px', color: selectedItem === item ? '#318CE7' : 'black' }"
              @click="showDetails(item)">
              {{ truncateText(item.topic) }}
              </p>
          </div>
        </b-col>
        <b-col sm="1" class="d-flex justify-content-center">
          <button v-if="data.length > 2" @click="scrollRight">
            <b-icon icon="chevron-right"></b-icon>
          </button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Overall',
  props: {
    data: { type: Array, default: () => [] },
    selectedItem: { type: Object, default: null },
  },
  data() {
    return {
      // selectedItem: null,
    };
  },
  methods: {
    scrollLeft() {
      this.$refs.scrollContainer.scrollBy({ left: -400, behavior: 'smooth' });
    },
    scrollRight() {
      this.$refs.scrollContainer.scrollBy({ left: 400, behavior: 'smooth' });
    },
    showDetails(item) {
      this.$emit('item-selected', item);
    },
    truncateText(text, length = 25) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
  },
};
</script>

<style scoped>
.custom-scrollbar{
  max-height: 100px;
  overflow-x: hidden;
  cursor: pointer;
  display: inline-block;
  max-width: 300px;
  border-style: solid;
  border-radius: 10px;
  border-color: lightgray;
  border-width: 1.5px;
}
.custom-scrollbar::-webkit-scrollbar {
  height: 8px;
}

</style>