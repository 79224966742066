<template>
  <div class="m-3">
    <h4 class="text-center modal-card__title mt-5">
      Select Platforms
    </h4>
    <p class="text-center">You can choose up to 4 platforms</p>

    <form
      class="ms-1 pt-3 w-100 row"
      @submit.prevent
    >
      <label v-for="platform in platforms" :key="platform.value" class="container col-6">
        <input v-model="selected_platforms" type="checkbox" :value="platform.value" />
        <div class="content">
          <img
            :src="require(`@/assets/icons/${platform.icon}.svg`)"
            :alt="platform.value + '-logo'"
            class="platform-icon"
          />
          <div>
            <h5 class="fw-bold">{{ platform.title }}</h5>
            <p class="mb-0">{{ platform.description }}</p>
          </div>
        </div>
      </label>
    </form>

    <div class="row">
      <div class="col">
        <p
          v-show="platform_error && !selected_platforms.length"
          class="text-sm text-danger"
        >
          Please choose 1 topic from the above list
        </p>
      </div>
      <div class="col">
        <button
          class="btn btn-primary float-right text-white"
          @click="next"
        >
          {{ "Next" | multilingual }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'form-platform-selection',
  props: {
    platforms: { type: Array, default: () => [] }
  },
  data() {
    return {
      selected_platforms: [],
      platform_error: false
    };
  },
  methods: {
    next() {
      if (!this.selected_platforms.length) this.platform_error = true;
      else this.$emit('submit', this.selected_platforms);
    }
  }
};
</script>

<style lang="css" scoped>
.platform-icon {
  height: 3rem;
  object-fit: contain;
  margin-top: 4px;
  aspect-ratio: 1/1;
}
.container {
  cursor: pointer;
  margin-bottom: 1rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.content {
  border-radius: 8px;
  border: 2px solid white;
  display: flex;
  gap: 12px;
  padding: 12px;
  height: 100% !important;
}
.container:hover input ~ .content {
  background: #f0f0f0;
  border: 2px solid #f0f0f0;
}
.container input:checked ~ .content {
  border: 2px solid #1565c0;
}
</style>
